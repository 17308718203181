'use client';

import {useEffect, PropsWithChildren} from 'react';
import posthog from 'posthog-js';
import {PostHogProvider as PostHogProviderInternal} from 'posthog-js/react';
import {usePathname, useSearchParams} from 'next/navigation';

import {POSTHOG_KEY, FE_TRACKING_ENDPOINT, ApplicationConfiguration} from '@/config';

if (ApplicationConfiguration.configurePosthog) {
  posthog.init(POSTHOG_KEY, {
    api_host: FE_TRACKING_ENDPOINT,
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  });
}

const IGNORED_PATHS = ['/cx/profile'];

export function PostHogPageView() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (pathname) {
      let url = window.origin + pathname;
      if (searchParams && searchParams.toString()) {
        url = url + `?${searchParams.toString()}`;
      }

      if (IGNORED_PATHS.some((path) => pathname === path)) {
        return;
      }

      posthog.capture('$pageview', {
        $current_url: url,
      });
    }
  }, [pathname, searchParams]);

  return null;
}

export function PostHogProvider({children}: PropsWithChildren) {
  return <PostHogProviderInternal client={posthog}>{children}</PostHogProviderInternal>;
}
